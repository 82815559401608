.logo {
	width: 90%;
	max-width: 450px;
	height: auto;
	padding-top: 20px;
	padding-left: 20px;
}
.phnText {
	font-weight: 600;
	font-size: 30px;
	line-height: 36px;
	color: #c9c9c9;
	text-decoration: none;
}
.hdrLink {
	padding-right: 20px;
	font-weight: 600;
	font-size: 30px;
	line-height: 36px;
	color: #00ff00;
	text-decoration: none;
}
.hdrLink:hover {
	color: #c9c9c9;
	cursor: pointer;
}
.navLink {
	font-weight: 600;
	font-size: 17px;
	line-height: 23px;
	color: #00ff00;
	text-decoration: none;
}
.navLink:hover {
	color: #c9c9c9;
	cursor: pointer;
}
.notNav:hover {
	cursor: pointer;
}

@media screen and (max-width: 1040px) {
	.phnText {
		font-size: 24px;
		line-height: 30px;
	}
	.hdrLink {
		font-size: 24px;
		line-height: 30px;
	}
}

@media screen and (max-width: 992px) {
	.logo {
		max-width: 550px;
		padding-top: 20px;
	}
}

.custom-toggler.navbar-toggler {
	border-color: rgb(0, 255, 0);
}

.custom-toggler .navbar-toggler-icon {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(0, 255, 0)' stroke-width='3' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}
