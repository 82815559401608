body {
	font-family: 'exoMed';
	font-size: 14px;
	line-height: 20px;
	overflow-x: hidden;
}
.textBold {
	font-family: 'exoBold';
}
.textSemi {
	font-family: 'exoSemi';
}
.textMed {
	font-family: 'exoMed';
}
.textReg {
	font-family: 'exoReg';
}
.div40 {
	height: 40px;
}
.errMsg {
	font-size: 14px;
	line-height: 20px;
	font-weight: 600;
	color: #c9c9c9;
}

/*** GENERAL PAGE STYLES ***/
.ftrText {
	display: block;
	margin-left: 10px;
	color: #00ff00;
}
.pgHdng {
	color: #c9c9c9;
}
.pgImg {
	width: 96%;
	max-width: 700px;
}
.pgText {
	width: 96%;
	padding: 10px 20px;
	font-family: 'exoSemi';
	font-size: 17px;
	line-height: 23px;
	color: #c9c9c9;
	border-radius: 20px;
	background-color: rgba(0, 0, 0, 0.4);
}

/*** GENERAL FORM STYLES ***/
.formContainer {
	width: 30%;
}
.frmLabel {
	font-family: 'exoBold';
	font-size: 14px;
	line-height: 20px;
	color: #c9c9c9;
}

@media screen and (max-width: 768px) {
	.formContainer {
		width: 90%;
	}
}

/*** GENERAL ADMIN STYLES ***/
.txtLink {
	font-size: 18px;
	line-height: 24px;
	font-weight: 500;
	color: #00ff00;
}
.txtLink:hover {
	cursor: pointer;
	color: #c9c9c9;
}

/*** FONTS ***/
@font-face {
	font-family: 'exoReg';
	src: local('exoReg'), url('../src/assets/fonts/Exo-Regular.ttf') format('truetype');
	font-weight: normal;
}

@font-face {
	font-family: 'exoMed';
	src: local('exoMed'), url('../src/assets/fonts/Exo-Medium.ttf') format('truetype');
	font-weight: normal;
}

@font-face {
	font-family: 'exoSemi';
	src: local('exoSemi'), url('../src/assets/fonts/Exo-SemiBold.ttf') format('truetype');
	font-weight: normal;
}

@font-face {
	font-family: 'exoBold';
	src: local('exoBold'), url('../src/assets/fonts/Exo-Bold.ttf') format('truetype');
	font-weight: normal;
}

@font-face {
	font-family: 'exoXBld';
	src: local('exoXBld'), url('../src/assets/fonts/Exo-ExtraBold.ttf') format('truetype');
	font-weight: normal;
}
