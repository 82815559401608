.dmnLink {
	font-size: 14px;
	line-height: 20px;
	font-weight: 500;
	color: #00ff00;
	text-decoration: none;
}
.dmnLink:hover {
	cursor: pointer;
	color: #c9c9c9;
}
.icon {
	width: auto;
	height: 16px;
}
.icon:hover {
	cursor: pointer;
}
.cardContainer {
	width: 70%;
	margin-left: auto;
	margin-right: auto;
	border-radius: 20px;
	border: 2px solid #00ff00;
}
.infoText {
	width: 96%;
	padding: 10px 20px;
	font-family: 'exoSemi';
	font-size: 15px;
	line-height: 21px;
	color: #c9c9c9;
	border-radius: 20px;
	background-color: rgba(0, 0, 0, 0.4);
}
.dmnChkBox {
	width: 18px;
	height: 18px;
	accent-color: #00ff00;
}
.hdngText {
	font-size: 15px;
	font-weight: 600;
	color: #c9c9c9;
	text-decoration: underline;
}
.dmnInfoText {
	font-size: 14px;
	line-height: 20px;
	font-weight: 500;
	color: #c9c9c9;
}

@media screen and (max-width: 768px) {
	.cardContainer {
		width: 94%;
	}
}
