.hmCard {
	width: 70%;
}
.title {
	font-weight: 600;
	color: #c9c9c9;
}
.content {
	color: #c9c9c9;
}
.linkDiv {
	position: absolute;
}
.link {
	position: absolute;
	left: 50%;
	bottom: 20px;
	transform: translateX(-50%);
	font-weight: 700;
	font-size: 18px;
	line-height: 24px;
	color: #00ff00;
	text-decoration: none;
}
.link:hover {
	color: #c9c9c9;
	cursor: pointer;
}

@media screen and (max-width: 1400px) {
	.hmCard {
		width: 96%;
	}
}

@media screen and (max-width: 767px) {
	.hmCard {
		width: 70%;
	}
}

@media screen and (max-width: 575px) {
	.hmCard {
		width: 90%;
	}
}
