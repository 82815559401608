.bgLarge {
	width: 100%;
	min-height: 100vh;
	background-size: cover;
	background-position: center;
	background-repeat: repeat-y;
	background-attachment: fixed;
	background-image: url('../../assets/images/bgLg.jpg');
}
.bgMedium {
	width: 100vw;
	min-height: 100vh;
	background-size: cover;
	background-position: center;
	background-repeat: repeat-y;
	background-attachment: fixed;
	background-image: url('../../assets/images/bgMd.jpg');
}
.bgSmall {
	width: 100vw;
	min-height: 100vh;
	background-size: cover;
	background-position: center;
	background-repeat: repeat-y;
	background-attachment: fixed;
	background-image: url('../../assets/images/bgSm.jpg');
}
