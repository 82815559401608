.spinnerParent {
	position: fixed;
	inset: 0px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.4);
}
.spinner {
	width: 50px;
	height: 50px;
	border: 4px solid rgb(0, 0, 0);
	border-top: 4px solid rgb(0, 255, 0);
	border-radius: 50%;
	animation: spin 1s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
