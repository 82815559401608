/*** INPUT BOX STYLES ***/
.inputBox,
.inputBox:focus {
	border: 2px solid black;
	color: #c9c9c9;
	background-color: #2e2e2e;
}
.testinput {
	display: none;
}

/*** BUTTON STYLES ***/
.button {
	width: auto;
	padding: 10px 20px;
	color: #00ff00;
	border: 1px solid #00ff00;
	border-radius: 10px;
	background-color: transparent;
	font-weight: 700;
	font-size: 18px;
}
.button:hover {
	border: 1px solid #c9c9c9;
	color: #c9c9c9;
	cursor: pointer;
}
.button:disabled {
	color: #2e2e2e;
	border: 1px solid #2e2e2e;
	cursor: default;
}

/*** FORGOT PASSWORD LINK ***/
.fpLink {
	font-size: 15px;
	line-height: 21px;
	font-weight: bold;
	color: #00ff00;
	text-decoration: none;
}
.fpLink:hover {
	color: #c9c9c9;
	cursor: pointer;
}

/*** CANCEL LINK ***/
.cncLink {
	font-family: 'exoBold';
	font-size: 14px;
	line-height: 20px;
	color: #00ff00;
	text-decoration: none;
}
.cncLink:hover {
	color: #c9c9c9;
	cursor: pointer;
}

/*** FORM CHECKBOX STYLES ***/
.chkBox {
	width: 22px;
	height: 22px;
	accent-color: #00ff00;
}
.dmnChkBox {
	width: 18px;
	height: 18px;
	accent-color: #00ff00;
}
