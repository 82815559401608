.cardHdng {
	font-size: 17px;
	font-weight: 500;
	color: #00ff00;
}
.cardHdng:hover {
	cursor: pointer;
	color: #c9c9c9;
}
.cardInfoContainer {
	width: 300px;
	padding: 20px;
	border: 2px solid #c9c9c9;
	border-radius: 20px;
}
.cardInfoText {
	font-size: 18px;
	line-height: 26px;
	font-weight: 500;
	color: #c9c9c9;
}
.cardText {
	font-size: 15px;
	font-weight: 500;
	color: #c9c9c9;
}
.cardLink {
	font-size: 15px;
	font-weight: 500;
	color: #00ff00;
}
.cardLink:hover {
	cursor: pointer;
	color: #c9c9c9;
}
.icoClose {
	width: auto;
	max-height: 18px;
}
.icoClose:hover {
	cursor: pointer;
}
.dmnChkBox {
	width: 18px;
	height: 18px;
	accent-color: #00ff00;
}
